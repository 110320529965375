@import url('assets/fonts/Durk/durk.css');

:root {
  --errorColor: #f44336;
  --whiteColor: #ffffff;
  --primaryColor: #b3995b;
  --secondaryColor: #212b36;
  --rgbaColor: rgba(145, 158, 171, 0.32);
}

.phoneInput .react-tel-input .special-label {
  left: 5px;
  font-size: 12px;
  font-weight: 100;
  color: var(--primaryColor);
  font-family: 'Public Sans', sans-serif;
  background-color: var(--secondaryColor);
  transform: translate(0px, -2px) scale(0.75);
}

.phoneInput .react-tel-input .form-control {
  width: 100%;
  border-radius: 8px;
  background: transparent;
  color: var(--whiteColor);
  border: 1px solid var(--rgbaColor) !important;
}

.phoneInput .react-tel-input .form-control:focus {
  box-shadow: none;
  border: 2px solid var(--rgbaColor) !important;
}

.phoneInputError .special-label {
  color: var(--errorColor) !important;
}

.react-tel-input .form-control.invalid-number {
  border: 2px solid var(--rgbaColor) !important;
}

.react-tel-input .form-control.invalid-number:focus {
  box-shadow: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

/* Firefox */
input[type='number'] {
  user-select: none;
  appearance: textfield;
  -moz-appearance: textfield;
}


.text-editor-toolbar {
  width: fit-content;
  border: none !important;
  margin-bottom: 8px !important;
}

.text-editor-editor {
  border: 2px solid var(--rgbaColor);
  padding-left: 10px;
  border-radius: 8px;
  font-family: 'arial';
  font-weight: 500;
}

.country-list {
background-color: #161c24 !important;
}

.react-tel-input .country-list .country.highlight {
  background-color: rgb(54 50 40) !important;
}

.react-tel-input .country-list .country:hover {
  background-color: rgb(54 50 40) !important;
}

.dial-code{
  color: #fff !important;
}